<template>
  <div class="mt-1">
    <!-- Table Container Card -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <div v-if="isHistorySidebarActive">
        <history-sidebar :is-project-sidebar-active="isHistorySidebarActive" @materialsCompleted="materialsCompleted" @toolingCompleted="toolingCompleted" @outsideCompleted="outsideCompleted" :active-tab-parameter="activeTabParameter" :project-id="projectId" v-model="isHistorySidebarActive" />
      </div>

      <div v-if="formData != null">
        <b-row v-if="formData != null" class="mb-25">
          <b-col cols="12" class="text-right" v-if="formData.status != 'passive'">
            <span v-if="formData.process == 'certification'">
              <a href="#certification" v-if="!formData.form_files.some((file) => file.fileType == 'certification')"><b-button variant="warning" class="mr-50">Certification Upload</b-button></a>
              <b-button variant="success" @click="completeForm()" class="mr-50">Project Complete</b-button>
            </span>

            <b-button style="width: 10rem;" variant="outline-success" v-if="formData.process == 'shop'" @click="processingForm()" class="mr-50">Processed</b-button>
            <b-button style="width: 10rem; background-color: #3498db!important; border-color: #3498db!important;" @click="viewHistory" class="mr-50 ">History</b-button>
            <b-button style="width: 10rem;" variant="outline-secondary" class="mr-50" @click="editForm()">Edit Project</b-button>
            <b-button style="width: 10rem;" variant="outline-primary" class="mr-50" target="_blank" :href="'https://orderform.harvard.systemstailormade.com/trackMyRequest.php?trackNumber=' + formData.trackNumber">Track Order</b-button>
            <b-button style="width: 10rem;" variant="secondary" class="mr-50" @click="junkQuote()">Archive</b-button>
            <b-button style="width: 10rem;" variant="outline-warning" class="mr-50" @click="duplicateForm()">Duplicate</b-button>
            <b-button style="width: 11rem;" variant="primary" v-if="formData.process == 'draft'" @click="generateQuote()">Create Quote</b-button>
            <b-button style="width: 11rem; font-size: 1.2rem;" variant="primary" v-else-if="formData.process == 'quote'" @click="generateQuote()">Manage Quote</b-button>
            <b-button style="width: 11rem;" variant="primary" v-else @click="generateQuote()">View Quote</b-button>
          </b-col>
        </b-row>

        <b-row v-if="formData != null" class="mb-25">
          <b-col cols="12" class="text-right" v-if="formData.status != 'passive'">
            <b-button style="width: 10rem;" :variant="'outline-secondary'" class="mr-50" @click="generateTicket()">Shop Ticket</b-button>
            <b-button style="width: 10rem;" :variant="formData.materialsCompleted ? '' : 'outline-secondary'" :style="formData.materialsCompleted ? 'background-color: #808080!important; border-color: #808080!important;' : ''" class="mr-50" @click="viewMaterials">Materials</b-button>
            <b-button style="width: 10rem;" :variant="formData.toolingCompleted ? '' : 'outline-secondary'" :style="formData.toolingCompleted ? 'background-color: #bf9b30!important; border-color: #bf9b30!important;' : ''" class="mr-50" @click="viewTooling">Tooling</b-button>
            <b-button style="width: 11rem;" :variant="formData.outsideCompleted ? '' : 'outline-secondary'" :style="formData.outsideCompleted ? 'background-color: #3498db!important; border-color: #3498db!important;' : ''" @click="viewOutsideProcess">O. Process</b-button>
          </b-col>
        </b-row>

        <span style="text-align: center;"
          ><h3 class="mt-3">Project Name : {{ formData.projectName ? formData.projectName : 'N/A' }}</h3></span
        >

        <div class="borderRow">
          <h4 class="bigTitle">Customer Information</h4>
          <table class="  custom-table">
            <tr>
              <td class="title">Date Generated</td>
              <td class="title">Job Number</td>
            </tr>
            <tr>
              <td class="information">{{ formattedCreatedAt }}</td>
              <td class="information">#{{ formData.id }}</td>
            </tr>

            <tr>
              <td class="title">Customer</td>
              <td class="title">Customer Email</td>
            </tr>
            <tr>
              <td class="information">{{ formData.contact }}</td>
              <td class="information">{{ formData.contactEmail }}</td>
            </tr>

            <tr>
              <td class="title">Customer Address</td>
              <td class="title">Customer Phone</td>
            </tr>
            <tr>
              <td class="information">{{ formData.companyAddress ? formData.companyAddress : 'N/A' }}</td>
              <td class="information">{{ formData.contactPhone ? formData.contactPhone : 'N/A' }}</td>
            </tr>

            <tr>
              <td class="title">Department</td>
              <td class="title">Lab</td>
            </tr>
            <tr>
              <td class="information">{{ formData.department }}</td>
              <td class="information">{{ formData.lab }}</td>
            </tr>

            <tr>
              <td class="title" v-if="formData.type == 'internal'">Charge String</td>
              <td class="title" v-else>PO Number</td>
              <td class="title">Tag Number</td>
            </tr>
            <tr>
              <td class="information">{{ formData.digCode ? formData.digCode : 'N/A' }}</td>
              <td class="information">{{ formData.tagNumber ? formData.tagNumber : 'N/A' }}</td>
            </tr>

            <tr>
              <td class="title">Investigator</td>
              <td class="title">Miscellaneous Information</td>
            </tr>
            <tr>
              <td class="information">{{ formData.principalInvestigator ? formData.principalInvestigator : 'N/A' }}</td>
              <td class="information">{{ formData.misc ? formData.misc : 'N/A' }}</td>
            </tr>
          </table>
        </div>

        <div class="borderRow" v-for="(part, partIndex) in formData.projectValues" :key="partIndex">
          <h4 class="bigTitle">Part #{{ part.partName }}</h4>
          <table class="  custom-table">
            <tr>
              <td class="titleQuater">Part Number</td>
              <td class="titleQuater">Revision</td>
              <td class="titleQuater">Qty</td>
              <td class="titleQuater">Part Name</td>
            </tr>
            <tr>
              <td class="information">{{ part.partNumber || 'N/A' }}</td>
              <td class="information">{{ part.revision || 'N/A' }}</td>
              <td class="information">{{ part.qty || 'N/A' }}</td>
              <td class="information">{{ part.partName || 'N/A' }}</td>
            </tr>

            <tr>
              <td class="titleQuater">OV Process Required?</td>
              <td class="titleQuater">Made before?</td>
              <td class="titleQuater">Want by</td>
              <td class="titleQuater">Need By</td>
            </tr>
            <tr>
              <td class="information">{{ part.ovProcess || 'N/A' }}</td>
              <td class="information">{{ part.madeBefore || 'N/A' }}</td>
              <td class="information">{{ part.wantBy || 'N/A' }}</td>
              <td class="information">{{ part.needBy || 'N/A' }}</td>
            </tr>

            <tr>
              <td class="titleQuater" colspan="2">Material</td>
            </tr>
            <tr>
              <td class="information" colspan="2">{{ part.materialName || 'N/A' }}</td>
            </tr>

            <tr v-if="formData != null">
              <td colspan="2" class="title">Supporting Documents</td>
              <td colspan="2" v-if="formData.process == 'certification' || formData.process == 'completed'" class="title">Material Certification</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="information file-images-container">
                  <div v-for="(item, index) in filteredFiles(formData.form_files, partIndex, 'file')" :key="index">
                    <div class="file-container">
                      <img v-if="item.name.endsWith('.pdf')" :src="require('@/assets/images/pdf.png')" alt="PDF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else-if="item.name.endsWith('.step') || item.name.endsWith('.stp') || item.name.endsWith('.STP') || item.name.endsWith('.STEP')" :src="require('@/assets/images/step.png')" alt="STEP Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else-if="item.name.endsWith('.dxf')" :src="require('@/assets/images/dxf.png')" alt="DXF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else :src="require('@/assets/images/invalid.png')" alt="Invalid Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <div class="file-name-container">
                        <span v-b-tooltip.hover.top="item.fileName" class="file-name">{{ truncateFileName(item.fileName, 17) }}</span>
                      </div>
                    </div>
                  </div>
                            <div v-if="filteredFiles(formData.form_files, partIndex, 'file').length == 0">
                    <div class="file-container">
                      <img :src="require('@/assets/images/no-image.png')" alt="Invalid Image" class="file-image" />
                      <div>
                        <span>No Supporting Documents</span>
                      </div>
                    </div>
                  </div>

                </div>

      

              </td>

              <td colspan="2" v-if="formData.process == 'certification' || formData.process == 'completed'">
                <div class="information file-images-container" style="width:100%; position: relative;">
                  <div v-for="(item, index) in filteredFiles(formData.form_files, partIndex, 'certification')" :key="index">
                    <div class="file-container" style="position: relative;">
                      <img v-if="item.name.endsWith('.pdf')" :src="require('@/assets/images/pdf.png')" alt="PDF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else-if="item.name.endsWith('.step') || item.name.endsWith('.stp')" :src="require('@/assets/images/step.png')" alt="STEP Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else-if="item.name.endsWith('.dxf')" :src="require('@/assets/images/dxf.png')" alt="DXF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <img v-else :src="require('@/assets/images/invalid.png')" alt="Invalid Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                      <button class="delete-button" @click="deleteCertification(item.id)" style="position: absolute; top: 5px; right: 5px; background-color: #a51c30; border: none; color: white; font-size: 0.8rem; cursor: pointer;">X</button>

                      <div class="file-name-container">
                        <span v-b-tooltip.hover.top="item.fileName" class="file-name">{{ truncateFileName(item.fileName, 17) }}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="filteredFiles(formData.form_files, partIndex, 'certification').length == 0">
                    <div class="file-container">
                      <img :src="require('@/assets/images/no-image.png')" alt="Invalid Image" class="file-image" />
                      <div>
                        <span>No Certification</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="formData.process == 'certification'">
              <td colspan="4">
                <vue-dropzone ref="certificatiDropzone" id="itemDropzone" :options="itemDropzoneOptions" v-on:vdropzone-sending="(file, xhr, formData) => itemSendingEvent(file, xhr, formData, partIndex)" @vdropzone-success="itemFileUploaded"> </vue-dropzone>
              </td>
            </tr>


            <tr> 

              <td class="titleQuater" colspan="4">Notes</td>
            </tr>
            <tr>
              <td class="information" colspan="4">{{ part.notes ? part.notes : 'N/A' }}</td>
            </tr>

          </table>
        </div>

        <div class="borderRow" id="certification" v-if="formData.process == 'certification' || formData.process == 'completed'">
          <h4 class="bigTitle">Certification of Compliance</h4>

          <div class="file-images-container mb-2">
            <div v-for="(item, index) in filteredFiles(formData.form_files, null, 'certification')" :key="index">
              <div class="file-container">
                <img v-if="item.name.endsWith('.pdf')" :src="require('@/assets/images/pdf.png')" alt="PDF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                <img v-else-if="item.name.endsWith('.step') || item.name.endsWith('.stp')" :src="require('@/assets/images/step.png')" alt="STEP Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                <img v-else-if="item.name.endsWith('.dxf')" :src="require('@/assets/images/dxf.png')" alt="DXF Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                <img v-else :src="require('@/assets/images/invalid.png')" alt="Invalid Image" @click="downloadFile(item.id, item.name)" class="file-image" />
                <div class="file-name-container">
                  <span v-b-tooltip.hover.top="item.fileName" class="file-name">{{ truncateFileName(item.fileName, 17) }}</span>
                </div>
              </div>
            </div>
          </div>

          <vue-dropzone v-if="formData.process == 'certification'" ref="complianceDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-sending="sendingEvent" @vdropzone-success="fileUploaded"> </vue-dropzone>
        </div>

        <div class="borderRow">
          <h4 class="bigTitle">Notes</h4>
          <table class="  custom-table">
            <tr>
              <td class="title">Notes</td>
            </tr>
            <tr>
              <td class="information">{{ formData.notes ? formData.notes : 'N/A' }}</td>
            </tr>
          </table>
        </div>
      </div>

      <b-modal id="notes-reminder" size="lg" title="Project Notes Reminder"  ok-title="Complete Project" ok-only @ok="completeProject" no-stacking>
        <notes-component :project-id="projectId"></notes-component>
      </b-modal>


    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone';
import HistorySidebar from './HistorySidebar.vue';
import NotesComponent from './NotesComponent.vue';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    vueDropzone: vue2Dropzone,
    HistorySidebar,
    NotesComponent
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      activeTabParameter: 1,
      isHistorySidebarActive: false,
      projectId: null,
      formData: null,
      loading: true,
      baseURL: store.state.app.baseURL,
      dropzoneOptions: {
        url: 'https://api.harvard.systemstailormade.com/public/api/uploadCertificaFile',
        maxFilesize: 5,
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD CERTIFICATION OF COMPLIANCE",
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      },
      itemDropzoneOptions: {
        url: 'https://api.harvard.systemstailormade.com/public/api/uploadCertificaFile',
        maxFilesize: 5,
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD CERTIFICATION OF ITEM",
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      },
    };
  },

  watch: {},

  methods: {
    materialsCompleted() {
      this.formData.materialsCompleted = !this.formData.materialsCompleted;
    },
    toolingCompleted() {
      this.formData.toolingCompleted = !this.formData.toolingCompleted;
    },
    outsideCompleted() {
      this.formData.outsideCompleted = !this.formData.outsideCompleted;
    },

    deleteCertification(certificationId) {
      store
        .dispatch('project/deleteCertificaFile', { id: certificationId })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔ Certification successfully deleted.',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.getForms();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    fileUploaded(file, response) {
      this.formData.form_files = response.form_files;
    },

    itemFileUploaded(file, response) {
      this.formData.form_files = response.form_files;
    },

    sendingEvent(file, xhr, formData) {
      formData.append('projectId', this.formData.id);
    },

    itemSendingEvent(file, xhr, formData, index) {
      formData.append('projectId', this.formData.id);
      formData.append('index', index);
    },

    truncateFileName(fileName, maxLength) {
      const truncatedName = fileName.length > maxLength ? fileName.slice(0, maxLength).toLowerCase() + '...' : fileName.toLowerCase();
      return truncatedName;
    },

    formatDate(date) {
      return this.$moment(date).format('M-D-YYYY');
    },

    duplicateForm() {
      this.$bvModal
        .msgBoxConfirm('A new project with the same details will be created. Do you want to continue?', {
          size: 'sm',
          title: 'Duplicate Project',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            this.$bvModal
        .msgBoxConfirm('Do you want an email to be sent to the customer?', {
          size: 'sm',
          title: 'Initial Email',
          okVariant: 'primary',
          okTitle: 'Yes, send email',
          cancelTitle: 'No, dont send',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
            store
              .dispatch('project/duplicateForm', { id: this.formData.id, emailChoice:value })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully duplicated.',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({ name: 'project-preview', params: { id: response.data } });
                this.getForms();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
        
        })

           
          }
        });
    },

    filteredFiles: (data, partIndex, type) => {
      return data.filter((file) => file.formIndex === partIndex && file.fileType == type);
    },

    editForm() {
      router.push({ name: 'project-edit', params: { id: this.formData.id } });
    },

    generateQuote() {
      this.$router.push({ name: 'generate-quote' });
    },

    generateTicket() {
      this.$router.push({ name: 'generate-ticket' });
    },

    viewHistory() {
      this.activeTabParameter = 1;
      this.isHistorySidebarActive = true;
    },
    viewMaterials() {
      this.activeTabParameter = 3;
      this.isHistorySidebarActive = true;
    },
    viewTooling() {
      this.activeTabParameter = 4;
      this.isHistorySidebarActive = true;
    },
    viewOutsideProcess() {
      this.activeTabParameter = 5;
      this.isHistorySidebarActive = true;
    },

    junkQuote() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to move project to archive?', {
          size: 'sm',
          title: 'Confirm Move to Archive',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/junkForm', this.formData.id)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully moved to archive',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.$router.push({ name: 'project-list' });
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    completeForm() {
      this.$nextTick(() => {
      this.$bvModal.show('notes-reminder');
      })

    
    },

    completeProject(){
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to complete project?', {
          size: 'sm',
          title: 'Confirm Complete',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/completeForm', this.formData.id)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully completed',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getForms();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    processingForm() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to mark the processing complete?', {
          size: 'sm',
          title: 'Confirm Complete',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/completeProcessing', this.formData.id)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully complted',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getForms();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    onRowSelected(item) {
      router.push({ name: 'project-edit', params: { id: item[0].id } });
    },

    getForms() {
      this.loading = true;

      this.projectId = parseInt(router.currentRoute.params.id);

      store
        .dispatch('project/getFormById', { id: router.currentRoute.params.id })
        .then((res) => {
          this.formData = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    downloadFile(fileId, fileName) {
      const swalWithLoading = this.$swal.mixin({
        title: 'Downloading...',
        text: 'Please wait while the file is being downloaded.',
        onOpen: () => {
          swalWithLoading.showLoading();

          store
            .dispatch('project/formFileDownload', { id: fileId })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();

              this.simulateFileDownload();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        },
      });

      swalWithLoading.fire();
    },

    simulateFileDownload() {
      setTimeout(() => {
        this.$swal.fire('Download Completed!', 'Your file has been successfully downloaded.', 'success');
      }, 200);
    },
  },

  created() {
    this.getForms();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },

    formattedCreatedAt() {
      return moment(this.formData.created_at).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.borderRow {
  margin-top: 2rem;
  border: 1px solid #777;
  padding: 2rem;
}

.bigTitle {
  margin-bottom: 1.5rem;
}
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 20px 5px;
}

.title {
  margin: 0;
  padding: 0;
  color: #555;
  width: 50%;
  font-size: 1.25rem;
}

.titleQuater {
  margin: 0;
  padding: 0;
  color: #555;
  width: 25%;
}

.information {
  padding: 10px;
  border: 1px solid #999;
}

.file-images-container {
  display: flex;
  flex-wrap: wrap;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.file-image {
  width: 100px;
  height: 100px;
  margin-bottom: 0px;
  cursor: pointer;
}

.file-name-container {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: transparent;
  border: none;
  color: white;
  background-color: #a51c30;
  font-size: 0.8rem;

  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.file-images-container:hover .delete-button {
  opacity: 1;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
./projectStoreModule
